<template>
  <div>
    <div
      v-if="
        pengukuran_kualitas_udara &&
        pengukuran_kualitas_pangan &&
        pengukuran_kualitas_air &&
        peralatan_photometer_kimia &&
        peralatan_pendukung_pengukuran_mikrobiologi &&
        peralatan_pendukung
      "
    >
      <!-- isian muali -->
      <div>
        <vue-html2pdf
          :show-layout="false"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="formulir-sanitarianlit"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="100%"
          :html-to-pdf-options="htmlToPdfOptions"
          ref="html2Pdf"
        >
          <div slot="pdf-content" style="padding: 10px">
            <div class="html2pdf__page-break">
              <profile-detil-data :profile="profile" />
              <p class="small_txt color_txt">
                {{ $date(created).format("MMMM YYYY") }}
              </p>
              <!-- TAB 1 -->
              <div color="#CFEEF2" class="mb-5 bg_header">
                <div class="fill_header">
                  <b>1. Pengukuran Kualitas Udara</b>
                </div>
              </div>
              <div class="bordered_card rounded-max">
                <div class="mb-3">
                  <b>Ketersediaan Alat Ukur Kualitas Udara</b>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Alat Pengukur Suhu
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        hide-details
                        class="ma-0 panjang-pilihan"
                        v-model="
                          pengukuran_kualitas_udara
                            .ketersediaan_alat_ukur_kualitas_udara
                            .alat_pengukuran_suhu.dataPenyusun_desc
                        "
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Alat Pengukur Kelembaban
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        hide-details
                        class="ma-0 panjang-pilihan"
                        v-model="
                          pengukuran_kualitas_udara
                            .ketersediaan_alat_ukur_kualitas_udara
                            .alat_pengukuran_kelembaban.dataPenyusun_desc
                        "
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Alat Pengukur kecepatan aliran udara
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        hide-details
                        class="ma-0 panjang-pilihan"
                        v-model="
                          pengukuran_kualitas_udara
                            .ketersediaan_alat_ukur_kualitas_udara
                            .alat_pengukur_kecepatan_aliran_udara
                            .dataPenyusun_desc
                        "
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Alat Pengukur pencahayaan
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_udara
                            .ketersediaan_alat_ukur_kualitas_udara
                            .alat_pengukur_kecepatan_aliran_pencahayaan
                            .dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Alat Pengukur Partikulat diudara
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_udara
                            .ketersediaan_alat_ukur_kualitas_udara
                            .alat_pengukur_partikulat_diudara.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Alat Pengukur Kebisingan
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        v-model="
                          pengukuran_kualitas_udara
                            .ketersediaan_alat_ukur_kualitas_udara
                            .alat_pengukur_kebisingan.dataPenyusun_desc
                        "
                        dense
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Alat Pengukur Jumlah kuman
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        v-model="
                          pengukuran_kualitas_udara
                            .ketersediaan_alat_ukur_kualitas_udara
                            .alat_pengukur_jumlah_kuman.dataPenyusun_desc
                        "
                        dense
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <div class="html2pdf__page-break">
              <!-- TAB 2 -->
              <div color="#CFEEF2" class="mb-5 bg_header">
                <div class="fill_header">
                  <b>2. Pengukuran Kualitas Pangan</b>
                </div>
              </div>
              <div class="bordered_card rounded-max">
                <div class="mb-3">
                  <b>Ketersediaan Reagen untuk pengukuran Parameter Kimia</b>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Methanyl Yellow</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        hide-details
                        v-model="
                          pengukuran_kualitas_pangan
                            .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                            .methanyl_yellow.dataPenyusun_desc
                        "
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Rodhamin B</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        hide-details
                        v-model="
                          pengukuran_kualitas_pangan
                            .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                            .rodhamin_b.dataPenyusun_desc
                        "
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Formaldehid</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        hide-details
                        v-model="
                          pengukuran_kualitas_pangan
                            .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                            .formaldehid.dataPenyusun_desc
                        "
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Boraks</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        hide-details
                        v-model="
                          pengukuran_kualitas_pangan
                            .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                            .boraks.dataPenyusun_desc
                        "
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="bordered_card rounded-max">
                <div class="mb-3">
                  <b>Ketersediaan Reagen untuk Parameter Mikrobiologi</b>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">E-Coli</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_pangan
                            .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi
                            .e_coli.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Coliform</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_pangan
                            .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi
                            .coliform.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="bordered_card rounded-max">
                <div class="mb-3">
                  <b>Ketersediaan Alat Ukur Parameter Fisik</b>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Alat Pengukur suhu
                        </p>
                      </div>
                    </v-col>
                    <!-- HANDLE -->
                    <v-col
                      cols="5"
                      v-if="
                        pengukuran_kualitas_pangan.ketersediaan_alat_ukur_parameter_fisik
                      "
                    >
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_pangan
                            .ketersediaan_alat_ukur_parameter_fisik
                            .alat_pengukur_suhu.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <div class="html2pdf__page-break">
              <!-- TAB 3 -->
              <div color="#CFEEF2" class="mb-5 bg_header">
                <div class="fill_header">
                  <b>3. Alur Pengelolaan Limbah Padat Covid-19</b>
                </div>
              </div>
              <div class="bordered_card rounded-max">
                <div class="mb-3">
                  <b>Ketersediaan Alat Ukur Parameter Fisik</b>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Warna (Colorimeter)
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_alat_ukur_parameter_fisik
                            .warna__colorimeter_.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Total Zat padat terlarut (TDS)
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_alat_ukur_parameter_fisik
                            .total_zat_padat_terlarut__tds_.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Kekeruhan (Turbiditymeter)
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_alat_ukur_parameter_fisik
                            .kekeruhan__turbiditymeter_.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="bordered_card rounded-max">
                <div class="mb-3">
                  <b>Ketersediaan Reagen untuk Pengukuran Parameter Kimia</b>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Arsen</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .arsen.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Flourida</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .flourida.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Nitrat (NO2)</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .nitrit_no2.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Nitrat (NO3)</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group row v-model="
                              pengukuran_kualitas_air
                                .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                                .nitrit_no3.dataPenyusun_desc
                            " dense hide-details class="ma-0">
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Sianida</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .sianida.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Alumunium</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        hide-details
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .alumunium.dataPenyusun_desc
                        "
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Besi</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .besi.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Kesadahan</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .kesadahan.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Klorida</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .klorida.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Mangan</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .mangan.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          pH meter (digital)
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .ph_meter_digital.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Seng</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .seng.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Sulfat</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .sulfat.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Tembaga</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .tembaga.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Amonia</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .amonia.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Sisa Klor</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .sisa_klor.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Total krom</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                            .total_krom.dataPenyusun_desc
                        "
                        dense
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="bordered_card rounded-max">
                <div class="mb-3">
                  <b
                    >Ketersediaan Reagen untuk pengukuran Parameter
                    Mikrobiologi</b
                  >
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">E-Coli</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_media_pengukuran_parameter_mikrobiologi
                            .e_coli.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Coliform</p>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          pengukuran_kualitas_air
                            .ketersediaan_media_pengukuran_parameter_mikrobiologi
                            .coliform.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Tersedia"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Tersedia"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <div class="html2pdf__page-break">
              <!-- TAB 4 -->
              <div color="#CFEEF2" class="mb-5 bg_header">
                <div class="fill_header">
                  <b>4. Peralatan Photometer Kimia</b>
                </div>
              </div>
              <div class="bordered_card rounded-max">
                <div class="mb-3">
                  <b>Ketersediaan Alat Photometer Kimia</b>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Photometer Kimia</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_photometer_kimia
                            .ketersediaan_alat_photometer_kimia.photometer_kimia
                            .dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <!-- TAB 5 -->
              <div color="#CFEEF2" class="mb-5 bg_header">
                <div class="fill_header">
                  <b>5. Peralatan Pendukung Pengukur Mikrobiologi</b>
                </div>
              </div>
              <div class="bordered_card rounded-max">
                <div class="mb-3">
                  <b>Ketersediaan Alat Pendukung Pengukuran Mikrobiologi</b>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Inkubator</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung_pengukuran_mikrobiologi
                            .ketersediaan_alat_pendukung_pengukuran_mikrobiologi
                            .inkubator.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Colony counter digital
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung_pengukuran_mikrobiologi
                            .ketersediaan_alat_pendukung_pengukuran_mikrobiologi
                            .colony_counter_digital.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>

            <div class="html2pdf__page-break">
              <!-- TAB 6 -->
              <div class="mb-5 bg_header">
                <div class="fill_header">
                  <b>6. Peralatan Pendukung</b>
                </div>
              </div>
              <div class="bordered_card rounded-max">
                <div class="mb-3">
                  <b>Ketersediaan Alat Pendukung Pengukuran Mikrobiologi</b>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Pencacah sampel/blender
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .pencacah_sampel_blender.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Mortar dan pestel
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .mortar_dan_pestel.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Global Positioning System
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .global_positioning_system.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Coolbox</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .coolbox.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Pinset dan gunting
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .pinset_dan_gunting.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Pipet transfer/spuit
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .pipet_transfer_spuit.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Salin steril</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .salin_steril.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">
                          Timbangan digital
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .timbangan_digital.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Aquades</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .aquades.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Wadah sampel</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .wadah_sampel.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Masker</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .masker.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
                          <div class="html2pdf__page-break">
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Kertas saring</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .kertas_saring.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Alcohol swab</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .alcohol_swab.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Corong kaca</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .corong_kaca.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Rak tabung</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .rak_tabung.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Lampu Spirtus</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .lampu_spirtus.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Sarung Tangan</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .sarung_tangan.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Botol Sampel</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .botol_sampel.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Tas Peralatan</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .tas_peralatan.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div class="pa-2 rounded-lg mb-4 bg_subsoal">
                  <v-row>
                    <v-col cols="6">
                      <div class="d-flex align-center" style="height: 100%">
                        <p class="ma-0 small_txt color_txt">Spatula</p>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-radio-group
                        row
                        dense
                        v-model="
                          peralatan_pendukung
                            .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                            .spatula.dataPenyusun_desc
                        "
                        hide-details
                        class="ma-0 panjang-pilihan"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Berfungsi"
                          value="2"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Ada - Tidak Berfungsi"
                          value="1"
                        ></v-radio>
                        <v-radio
                          color="#00ccb6"
                          label="Tidak Ada"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <!-- ================================================================================================================================================================================================== -->

            <!-- end -->
          </div>
        </vue-html2pdf>
      </div>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import profileDetilData from "../../Data/Detail/profileDetilData.vue";
export default {
  props: ["id", 'profile', 'created'],
  components: { VueHtml2pdf, profileDetilData },
  computed: {
    pengukuran_kualitas_udara() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.pengukuran_kualitas_udara;
      }
      return data;
    },
    pengukuran_kualitas_pangan() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.pengukuran_kualitas_pangan;
      }
      return data;
    },
    pengukuran_kualitas_air() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.pengukuran_kualitas_air;
      }
      return data;
    },
    peralatan_photometer_kimia() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.peralatan_photometer_kimia;
      }
      return data;
    },
    peralatan_pendukung_pengukuran_mikrobiologi() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.peralatan_pendukung_pengukuran_mikrobiologi;
      }
      return data;
    },
    peralatan_pendukung() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.peralatan_pendukung;
      }
      return data;
    },
  },
  name: "printSanitarian",
  data() {
    return {
      loading: false,
      isianData: null,
      htmlToPdfOptions: {
        filename: 'formulir-sanitarian',
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      let body = {
        report_id: this.id ? this.id : this.$route.params.id,
      };
      console.log(body);
      this.$store
        .dispatch("formulir/getDataFormulirSanitarian", body)
        .then((data) => {
          this.isianData = data.data.sanitarian_kit;
        });
    },
    generateForm() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped>
.custom_input {
  border-radius: 8px;
  width: 150px;
}
.custom_input::placeholder {
  font-size: small;
}
.custom_input:focus {
  outline: none;
}
.custom_mini {
  border-radius: 8px;
  width: 100px;
}
.custom_mini::placeholder {
  font-size: small;
}
.custom_mini:focus {
  outline: none;
}
.custom_long_input {
  border-radius: 8px;
  width: 100%;
}
.custom_long_input:focus {
  outline: none;
}
.custom_long_input::placeholder {
  font-size: small;
}

.custom_date {
  width: 120px;
}

.custom_date::placeholder {
  font-size: small;
}
.custom_date:focus {
  outline: none;
}
.temp_stepper_header {
  background: none !important;
  box-shadow: none !important;
}

.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}
.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
}
.marlef {
  margin-right: 50px;
}
.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}
.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}
.fill_header {
  background: #00b4cc !important;
  width: 60%;
  padding: 10px;
  color: #fff;
}
.bordered_card {
  padding: 10px;
  margin-bottom: 10px;
}
.pa-2 {
  padding: 5px;
}
.pa-2 {
  padding: 10px;
}
.px-5 {
  padding: 0 15px;
}
.py-3 {
  padding: 10px 0;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 15px;
}
.mb-5 {
  margin-bottom: 20px;
}
.d-flex {
  display: flex;
}
.ma-0 {
  margin: 0;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.bg_header {
  background: #cfeef2;
  border-radius: 8px;
}
.bg_subsoal {
  background: #f6f6f6;
  border-radius: 8px;
}
</style>