var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.pengukuran_kualitas_udara &&
      _vm.pengukuran_kualitas_pangan &&
      _vm.pengukuran_kualitas_air &&
      _vm.peralatan_photometer_kimia &&
      _vm.peralatan_pendukung_pengukuran_mikrobiologi &&
      _vm.peralatan_pendukung
    )?_c('div',[_c('div',[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"enable-download":true,"preview-modal":true,"paginate-elements-by-height":1400,"filename":"formulir-sanitarianlit","pdf-quality":2,"manual-pagination":false,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"100%","html-to-pdf-options":_vm.htmlToPdfOptions}},[_c('div',{staticStyle:{"padding":"10px"},attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticClass:"html2pdf__page-break"},[_c('profile-detil-data',{attrs:{"profile":_vm.profile}}),_c('p',{staticClass:"small_txt color_txt"},[_vm._v(" "+_vm._s(_vm.$date(_vm.created).format("MMMM YYYY"))+" ")]),_c('div',{staticClass:"mb-5 bg_header",attrs:{"color":"#CFEEF2"}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("1. Pengukuran Kualitas Udara")])])]),_c('div',{staticClass:"bordered_card rounded-max"},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Ketersediaan Alat Ukur Kualitas Udara")])]),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Alat Pengukur Suhu ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukuran_suhu.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukuran_suhu, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_udara\n                          .ketersediaan_alat_ukur_kualitas_udara\n                          .alat_pengukuran_suhu.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Alat Pengukur Kelembaban ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukuran_kelembaban.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukuran_kelembaban, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_udara\n                          .ketersediaan_alat_ukur_kualitas_udara\n                          .alat_pengukuran_kelembaban.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Alat Pengukur kecepatan aliran udara ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukur_kecepatan_aliran_udara
                          .dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukur_kecepatan_aliran_udara
                          , "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_udara\n                          .ketersediaan_alat_ukur_kualitas_udara\n                          .alat_pengukur_kecepatan_aliran_udara\n                          .dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Alat Pengukur pencahayaan ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukur_kecepatan_aliran_pencahayaan
                          .dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukur_kecepatan_aliran_pencahayaan
                          , "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_udara\n                          .ketersediaan_alat_ukur_kualitas_udara\n                          .alat_pengukur_kecepatan_aliran_pencahayaan\n                          .dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Alat Pengukur Partikulat diudara ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukur_partikulat_diudara.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukur_partikulat_diudara, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_udara\n                          .ketersediaan_alat_ukur_kualitas_udara\n                          .alat_pengukur_partikulat_diudara.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Alat Pengukur Kebisingan ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukur_kebisingan.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukur_kebisingan, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_udara\n                          .ketersediaan_alat_ukur_kualitas_udara\n                          .alat_pengukur_kebisingan.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Alat Pengukur Jumlah kuman ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukur_jumlah_kuman.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_udara
                          .ketersediaan_alat_ukur_kualitas_udara
                          .alat_pengukur_jumlah_kuman, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_udara\n                          .ketersediaan_alat_ukur_kualitas_udara\n                          .alat_pengukur_jumlah_kuman.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1)])],1),_c('div',{staticClass:"html2pdf__page-break"},[_c('div',{staticClass:"mb-5 bg_header",attrs:{"color":"#CFEEF2"}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("2. Pengukuran Kualitas Pangan")])])]),_c('div',{staticClass:"bordered_card rounded-max"},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Ketersediaan Reagen untuk pengukuran Parameter Kimia")])]),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Methanyl Yellow")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_pangan
                          .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                          .methanyl_yellow.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_pangan
                          .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                          .methanyl_yellow, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_pangan\n                          .ketersediaan_reagen_untuk__pengukuran_parameter_kimia\n                          .methanyl_yellow.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Rodhamin B")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_pangan
                          .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                          .rodhamin_b.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_pangan
                          .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                          .rodhamin_b, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_pangan\n                          .ketersediaan_reagen_untuk__pengukuran_parameter_kimia\n                          .rodhamin_b.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Formaldehid")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_pangan
                          .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                          .formaldehid.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_pangan
                          .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                          .formaldehid, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_pangan\n                          .ketersediaan_reagen_untuk__pengukuran_parameter_kimia\n                          .formaldehid.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Boraks")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_pangan
                          .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                          .boraks.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_pangan
                          .ketersediaan_reagen_untuk__pengukuran_parameter_kimia
                          .boraks, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_pangan\n                          .ketersediaan_reagen_untuk__pengukuran_parameter_kimia\n                          .boraks.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1)]),_c('div',{staticClass:"bordered_card rounded-max"},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Ketersediaan Reagen untuk Parameter Mikrobiologi")])]),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("E-Coli")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_pangan
                          .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi
                          .e_coli.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_pangan
                          .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi
                          .e_coli, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_pangan\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi\n                          .e_coli.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Coliform")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_pangan
                          .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi
                          .coliform.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_pangan
                          .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi
                          .coliform, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_pangan\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_mikrobiologi\n                          .coliform.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1)]),_c('div',{staticClass:"bordered_card rounded-max"},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Ketersediaan Alat Ukur Parameter Fisik")])]),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Alat Pengukur suhu ")])])]),(
                      _vm.pengukuran_kualitas_pangan.ketersediaan_alat_ukur_parameter_fisik
                    )?_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_pangan
                          .ketersediaan_alat_ukur_parameter_fisik
                          .alat_pengukur_suhu.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_pangan
                          .ketersediaan_alat_ukur_parameter_fisik
                          .alat_pengukur_suhu, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_pangan\n                          .ketersediaan_alat_ukur_parameter_fisik\n                          .alat_pengukur_suhu.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1):_vm._e()],1)],1)])]),_c('div',{staticClass:"html2pdf__page-break"},[_c('div',{staticClass:"mb-5 bg_header",attrs:{"color":"#CFEEF2"}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("3. Alur Pengelolaan Limbah Padat Covid-19")])])]),_c('div',{staticClass:"bordered_card rounded-max"},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Ketersediaan Alat Ukur Parameter Fisik")])]),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Warna (Colorimeter) ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_alat_ukur_parameter_fisik
                          .warna__colorimeter_.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_alat_ukur_parameter_fisik
                          .warna__colorimeter_, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_alat_ukur_parameter_fisik\n                          .warna__colorimeter_.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Total Zat padat terlarut (TDS) ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_alat_ukur_parameter_fisik
                          .total_zat_padat_terlarut__tds_.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_alat_ukur_parameter_fisik
                          .total_zat_padat_terlarut__tds_, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_alat_ukur_parameter_fisik\n                          .total_zat_padat_terlarut__tds_.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Kekeruhan (Turbiditymeter) ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_alat_ukur_parameter_fisik
                          .kekeruhan__turbiditymeter_.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_alat_ukur_parameter_fisik
                          .kekeruhan__turbiditymeter_, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_alat_ukur_parameter_fisik\n                          .kekeruhan__turbiditymeter_.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1)]),_c('div',{staticClass:"bordered_card rounded-max"},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Ketersediaan Reagen untuk Pengukuran Parameter Kimia")])]),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Arsen")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .arsen.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .arsen, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .arsen.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Flourida")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .flourida.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .flourida, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .flourida.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Nitrat (NO2)")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .nitrit_no2.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .nitrit_no2, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .nitrit_no2.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Nitrat (NO3)")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                            _vm.pengukuran_kualitas_air
                              .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                              .nitrit_no3.dataPenyusun_desc
                          ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                              .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                              .nitrit_no3, "dataPenyusun_desc", $$v)},expression:"\n                            pengukuran_kualitas_air\n                              .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                              .nitrit_no3.dataPenyusun_desc\n                          "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Sianida")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .sianida.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .sianida, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .sianida.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Alumunium")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .alumunium.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .alumunium, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .alumunium.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Besi")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .besi.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .besi, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .besi.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Kesadahan")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .kesadahan.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .kesadahan, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .kesadahan.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Klorida")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .klorida.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .klorida, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .klorida.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Mangan")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .mangan.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .mangan, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .mangan.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" pH meter (digital) ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .ph_meter_digital.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .ph_meter_digital, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .ph_meter_digital.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Seng")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .seng.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .seng, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .seng.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Sulfat")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .sulfat.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .sulfat, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .sulfat.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Tembaga")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .tembaga.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .tembaga, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .tembaga.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Amonia")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .amonia.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .amonia, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .amonia.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Sisa Klor")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .sisa_klor.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .sisa_klor, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .sisa_klor.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Total krom")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .total_krom.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia
                          .total_krom, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_reagen_untuk_pengukuran_parameter_kimia\n                          .total_krom.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1)]),_c('div',{staticClass:"bordered_card rounded-max"},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Ketersediaan Reagen untuk pengukuran Parameter Mikrobiologi")])]),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("E-Coli")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_media_pengukuran_parameter_mikrobiologi
                          .e_coli.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_media_pengukuran_parameter_mikrobiologi
                          .e_coli, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_media_pengukuran_parameter_mikrobiologi\n                          .e_coli.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Coliform")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.pengukuran_kualitas_air
                          .ketersediaan_media_pengukuran_parameter_mikrobiologi
                          .coliform.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.pengukuran_kualitas_air
                          .ketersediaan_media_pengukuran_parameter_mikrobiologi
                          .coliform, "dataPenyusun_desc", $$v)},expression:"\n                        pengukuran_kualitas_air\n                          .ketersediaan_media_pengukuran_parameter_mikrobiologi\n                          .coliform.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tersedia","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Tersedia","value":"0"}})],1)],1)],1)],1)])]),_c('div',{staticClass:"html2pdf__page-break"},[_c('div',{staticClass:"mb-5 bg_header",attrs:{"color":"#CFEEF2"}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("4. Peralatan Photometer Kimia")])])]),_c('div',{staticClass:"bordered_card rounded-max"},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Ketersediaan Alat Photometer Kimia")])]),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Photometer Kimia")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_photometer_kimia
                          .ketersediaan_alat_photometer_kimia.photometer_kimia
                          .dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_photometer_kimia
                          .ketersediaan_alat_photometer_kimia.photometer_kimia
                          , "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_photometer_kimia\n                          .ketersediaan_alat_photometer_kimia.photometer_kimia\n                          .dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1)]),_c('div',{staticClass:"mb-5 bg_header",attrs:{"color":"#CFEEF2"}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("5. Peralatan Pendukung Pengukur Mikrobiologi")])])]),_c('div',{staticClass:"bordered_card rounded-max"},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Ketersediaan Alat Pendukung Pengukuran Mikrobiologi")])]),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Inkubator")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung_pengukuran_mikrobiologi
                          .ketersediaan_alat_pendukung_pengukuran_mikrobiologi
                          .inkubator.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung_pengukuran_mikrobiologi
                          .ketersediaan_alat_pendukung_pengukuran_mikrobiologi
                          .inkubator, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung_pengukuran_mikrobiologi\n                          .ketersediaan_alat_pendukung_pengukuran_mikrobiologi\n                          .inkubator.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Colony counter digital ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung_pengukuran_mikrobiologi
                          .ketersediaan_alat_pendukung_pengukuran_mikrobiologi
                          .colony_counter_digital.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung_pengukuran_mikrobiologi
                          .ketersediaan_alat_pendukung_pengukuran_mikrobiologi
                          .colony_counter_digital, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung_pengukuran_mikrobiologi\n                          .ketersediaan_alat_pendukung_pengukuran_mikrobiologi\n                          .colony_counter_digital.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1)])]),_c('div',{staticClass:"html2pdf__page-break"},[_c('div',{staticClass:"mb-5 bg_header"},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("6. Peralatan Pendukung")])])]),_c('div',{staticClass:"bordered_card rounded-max"},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Ketersediaan Alat Pendukung Pengukuran Mikrobiologi")])]),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Pencacah sampel/blender ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .pencacah_sampel_blender.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .pencacah_sampel_blender, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .pencacah_sampel_blender.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Mortar dan pestel ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .mortar_dan_pestel.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .mortar_dan_pestel, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .mortar_dan_pestel.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Global Positioning System ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .global_positioning_system.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .global_positioning_system, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .global_positioning_system.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Coolbox")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .coolbox.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .coolbox, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .coolbox.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Pinset dan gunting ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .pinset_dan_gunting.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .pinset_dan_gunting, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .pinset_dan_gunting.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Pipet transfer/spuit ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .pipet_transfer_spuit.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .pipet_transfer_spuit, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .pipet_transfer_spuit.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Salin steril")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .salin_steril.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .salin_steril, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .salin_steril.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbangan digital ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .timbangan_digital.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .timbangan_digital, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .timbangan_digital.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Aquades")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .aquades.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .aquades, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .aquades.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Wadah sampel")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .wadah_sampel.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .wadah_sampel, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .wadah_sampel.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Masker")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .masker.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .masker, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .masker.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1)])]),_c('div',{staticClass:"html2pdf__page-break"},[_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Kertas saring")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .kertas_saring.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .kertas_saring, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .kertas_saring.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Alcohol swab")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .alcohol_swab.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .alcohol_swab, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .alcohol_swab.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Corong kaca")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .corong_kaca.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .corong_kaca, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .corong_kaca.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Rak tabung")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .rak_tabung.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .rak_tabung, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .rak_tabung.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Lampu Spirtus")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .lampu_spirtus.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .lampu_spirtus, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .lampu_spirtus.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Sarung Tangan")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .sarung_tangan.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .sarung_tangan, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .sarung_tangan.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Botol Sampel")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .botol_sampel.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .botol_sampel, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .botol_sampel.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Tas Peralatan")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .tas_peralatan.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .tas_peralatan, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .tas_peralatan.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1),_c('div',{staticClass:"pa-2 rounded-lg mb-4 bg_subsoal"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Spatula")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0 panjang-pilihan",attrs:{"row":"","dense":"","hide-details":""},model:{value:(
                        _vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .spatula.dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.peralatan_pendukung
                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_
                          .spatula, "dataPenyusun_desc", $$v)},expression:"\n                        peralatan_pendukung\n                          .ketersediaan_alat_pendukung_pengukuran__mikrobiologi_\n                          .spatula.dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Berfungsi","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada - Tidak Berfungsi","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak Ada","value":"0"}})],1)],1)],1)],1)])])])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }